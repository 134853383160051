import React, { Fragment, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { StyleSheet, css } from 'aphrodite';
import Img, { FluidObject } from 'gatsby-image';
import { Text } from 'components/Text/Text';

import { Link } from 'gatsby';
import BackgroundVideo from 'components/BackgroundVideo/BackgroundVideo';
import PageTopSection from 'components/PageTopSection/PageTopSection';
import SEO from 'components/SEO/SEO';
import VideoPlayPauseButton from 'components/VideoPlayPauseButton/VideoPlayPauseButton';
import { convertToMobileVW, convertToVW } from 'shared/utils';
import { black, white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';
const bg = require('images/herov2.jpg');

interface Props {
  data: {
    contentfulProcessPage: {
      mainHeader: string;
      backgroundTexture: {
        fluid: FluidObject;
      };
      backgroundVideo: {
        file: {
          url: string;
        };
      };
      processGrid: {
        id: string;
        header: string;
        altText: string;
        topDescription: {
          topDescription: string;
        };
        bottomDescription: {
          bottomDescription: string;
        };
        image: {
          fluid: FluidObject;
        };
      }[];
      processVideo: {
        file: {
          url: string;
        };
      };
    };
  };
  location: { pathname: string };
}

const ProcessPage = ({
  data: {
    contentfulProcessPage: {
      backgroundTexture,
      backgroundVideo,
      mainHeader,
      processGrid,
      processVideo: {
        file: { url },
      },
    },
  },
  location: { pathname },
}: Props) => {
  const backgroundVidRef = useRef<HTMLVideoElement | null>(null);
  const processVidRef = useRef<HTMLVideoElement | null>(null);
  const [isVideoPaused, setIsVideoPaused] = useState<boolean | undefined>(
    processVidRef.current?.paused
  );

  return (
    <main>
      <SEO title="Process" pathname={pathname} />
      <BackgroundVideo
        backgroundTexture={backgroundTexture}
        backgroundVideo={backgroundVideo}
        vidRef={backgroundVidRef}
      />
      <div className={css(styles.processHero)}>
        <h1 className={css(styles.processHeroText)}>
          A LOOK BEHIND THE MONOGRAM PROCESS.
        </h1>
      </div>
      <section className={css(styles.processGrid)}>
        {processGrid.map(
          (
            {
              id,
              header,
              topDescription: { topDescription },
              bottomDescription,
              altText,
              image: { fluid },
            },
            index
          ) => {
            const isMiddle = index % 2 !== 0;

            return (
              <Fragment key={id}>
                <Img
                  fluid={fluid}
                  alt={altText}
                  className={css(styles.gridItemImage)}
                  style={{ gridColumn: isMiddle ? 2 : 1, gridRow: index + 1 }}
                />
                <div
                  className={css(styles.gridItemText)}
                  style={{ gridColumn: isMiddle ? 1 : 2, gridRow: index + 1 }}
                >
                  <h2 className={css(styles.header)}>{header}</h2>
                  <p className={css(styles.topDescription)}>{topDescription}</p>
                  {bottomDescription && (
                    <p className={css(styles.bottomDescription)}>
                      {bottomDescription.bottomDescription}
                    </p>
                  )}
                </div>
              </Fragment>
            );
          }
        )}
      </section>

      <div className={css(styles.bottomVideo)}>
        <video
          autoPlay
          muted
          playsInline
          loop
          className={css(styles.video)}
          ref={processVidRef}
        >
          <source src={url} />
          Sorry, your browser doesn't support HTML5 video :(
        </video>
        <button
          className={css(styles.pauseButton)}
          onClick={() => {
            isVideoPaused
              ? processVidRef.current?.play()
              : processVidRef.current?.pause();
            setIsVideoPaused((isVideoPaused) => !isVideoPaused);
          }}
        >
          <Text
            text={isVideoPaused ? 'Play' : 'Pause'}
            extraStyle={styles.pauseButtonText}
          />
        </button>
        <div className={css(styles.buttonWrapper)}>
          <Link to="/products">
            <button className={css(styles.button)}>
              <Text text="Shop Monogram" extraStyle={styles.buttonText} />
            </button>
          </Link>
        </div>
      </div>
      <div className={css(styles.productCtaContainer)}>
        <img
          id="herov2"
          className={css(styles.productCta)}
          src={bg}
          alt="Reviewed by Accessible 360"
        />
        <div className={css(styles.textWrapper)}>
          <p className={css(styles.subheader)}>HYPE WILLIAMS X SLIM AARONS</p>
          <h1 className={css(styles.title)}>THE GOOD LIFE, REDEFINED</h1>
          <div className={css(styles.buttonWrapperHardcode)}>
            <a href="/editorial/thegoodliferedefined">
              <button className={css(styles.buttonHardcode)}>
                <p className={css(styles.buttonTextHardcode)}>
                  view behind the scenes
                </p>
              </button>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProcessPage;

const styles = StyleSheet.create({
  processHero: {
    paddingTop: '140px',
    height: '100%',
    position: 'relative',

    [BREAKPOINTS.TABLET]: {
      height: '100vw',
    },
  },
  processHeroText: {
    fontFamily: 'Titling Gothic FB Narrow Standard',
    textTransform: 'uppercase',
    fontSize: '10vw',
    lineHeight: 1,
    zIndex: 10,
    color: white,
    wordWrap: 'break-word',
    width: '65vw',
    marginLeft: '20px',
    [BREAKPOINTS.TABLET]: {
      marginTop: '110px',
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      fontSize: '160px',
      width: '1300px',
    },
    [BREAKPOINTS.MOBILE]: {
      // margin
    },
  },
  processGrid: {
    position: 'relative',
    display: 'grid',
    gridTemplateRows: `repeat(3, ${convertToVW(957)})`,
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: convertToVW(161),
    padding: `0 ${convertToVW(133)}`,
    zIndex: 1,
    backgroundColor: black,
    borderTop: `1px solid ${white}`,
    marginTop: convertToVW(250),
    color: white,
    [BREAKPOINTS.TABLET]: {
      display: 'flex',
      flexDirection: 'column',
      padding: `0 ${convertToMobileVW(29)}`,
    },
  },
  header: {
    fontFamily: 'Titling Gothic FB Compressed Standard',
    textTransform: 'uppercase',
    fontSize: '50px',
    width: '100%',
    marginBottom: '2vw',
    [BREAKPOINTS.TABLET]: {
      marginTop: convertToMobileVW(41),
    },
  },
  gridItemText: {
    transform: `translateY(${convertToVW(-127.5)})`,
    fontFamily: 'Titling Gothic FB Normal Regular',
    fontSize: '12px',
    lineHeight: 1.5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [BREAKPOINTS.TABLET]: {
      width: 'fit-content',
      marginTop: convertToMobileVW(41),
      transform: 'translateY(-150px)',
    },
    [BREAKPOINTS.MOBILE]: {
      transform: 'translateY(-75px)',
    },
  },
  gridItemImage: {
    transform: `translateY(${convertToVW(-127.5)})`,
    placeSelf: 'center',
    height: convertToVW(957),
    width: '100%',
    border: `1px solid ${white}`,
    [BREAKPOINTS.TABLET]: {
      marginTop: convertToMobileVW(100),
      transform: 'translateY(-150px)',
    },
    [BREAKPOINTS.MOBILE]: {
      transform: 'translateY(-75px)',
    },
  },
  topDescription: {
    marginBottom: convertToVW(30),
    [BREAKPOINTS.TABLET]: {
      margin: 0,
    },
  },
  bottomDescription: {},
  videoContainer: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center center',
    width: '100%',
    zIndex: 1,
    padding: `0 ${convertToVW(133)} ${convertToVW(158)}`,
    backgroundColor: black,
    [BREAKPOINTS.TABLET]: {
      padding: 0,
    },
  },

  pauseButton: {
    top: '20px',
    right: '20px',
    fontFamily: 'Titling Gothic FB Wide',
    position: 'absolute',
    color: white,
    textAlign: 'right',
    padding: '0px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    zIndex: 10,
    ':focus': {
      border: '1px solid white',
      backgroundColor: black,
    },
  },
  pauseButtonText: {
    textTransform: 'uppercase',
    fontSize: '12px',
    [BREAKPOINTS.TABLET]: {},
  },
  bottomVideo: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100vw * 9 / 16)',
    borderTop: `1px solid ${white}`,
    overflow: 'hidden',

    [BREAKPOINTS.TABLET]: {
      height: '100vw',
    },
  },
  video: {
    width: '100%',
    aspectRatio: '16 / 9',
    objectFit: 'cover',
    height: '100%',
    filter: 'brightness(0.4)',

    [BREAKPOINTS.TABLET]: {
      height: '100vw',
    },
  },
  buttonWrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',

    [BREAKPOINTS.TABLET]: {
      margin: '-1rem auto',
      transform: 'scale(0.75)',
    },
  },
  button: {
    color: white,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    height: convertToVW(123),
    width: '360px',
    maxWidth: '450px',
    maxHeight: '100px',
    minHeight: '70px',
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    transition: 'all 0.3s ease-in-out',
    [BREAKPOINTS.FOOTER_BREAK_12]: {
      width: '450px',
    },
    [BREAKPOINTS.FOOTER_BREAK_95]: {
      width: '270px',
    },
    ':focus': {
      border: '1px solid white',
      backgroundColor: black,
    },
    ':hover': {
      backgroundColor: white,
      transition: 'background 0.3s ease-in-out',
    },
    ':hover > p': {
      color: black,
      transition: 'color 0.3s ease-in-out',
    },
  },
  buttonText: {
    fontSize: '12px',
    [BREAKPOINTS.TABLET]: {},
  },
  productCta: {
    display: 'block',
    filter: 'brightness(0.5)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  productCtaContainer: {
    borderTop: '1px solid white',
    position: 'relative',
    backgroundColor: black,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'calc(100vw * 9 / 16)',

    [BREAKPOINTS.TABLET]: {
      height: '100vw',
    },
  },
  textWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',

    [BREAKPOINTS.TABLET]: {
      width: 'calc(100vw - 80px)',
    },
  },
  subheader: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'Titling Gothic FB Wide',
    color: white,
    lineHeight: '12px',
    textTransform: 'uppercase',
    paddingBottom: '20px',
    [BREAKPOINTS.TABLET]: {
      fontSize: 8,
    },
  },
  title: {
    color: white,
    fontFamily: 'Titling Gothic FB Compressed Standard',
    textTransform: 'uppercase',
    fontSize: '70px',
    lineHeight: '70px',
    paddingBottom: '20px',
    [BREAKPOINTS.MOBILE]: {
      fontSize: 40,
      lineHeight: 1,
    },
  },
  buttonWrapperHardcode: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',

    [BREAKPOINTS.MOBILE]: {
      margin: '-1rem auto',
      transform: 'scale(0.75)',
    },
  },
  buttonHardcode: {
    color: black,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    height: convertToVW(123),
    width: '360px',
    maxWidth: '450px',
    maxHeight: '100px',
    minHeight: '70px',
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: 'all 0.3s ease-in-out',
    [BREAKPOINTS.FOOTER_BREAK_12]: {
      width: '450px',
    },
    [BREAKPOINTS.FOOTER_BREAK_95]: {
      width: '270px',
    },
  },
  buttonTextHardcode: {
    fontSize: 12,
    color: black,
    fontFamily: 'Titling Gothic FB Wide',
    [BREAKPOINTS.TABLET]: {},
  },
});

export const query = graphql`
  query ProcessPageQuery {
    contentfulProcessPage {
      mainHeader
      backgroundTexture {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      backgroundVideo {
        file {
          url
        }
      }
      processGrid {
        id
        header
        altText
        topDescription {
          topDescription
        }
        bottomDescription {
          bottomDescription
        }
        image {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
      processVideo {
        file {
          url
        }
      }
    }
  }
`;
