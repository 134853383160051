import React, { useState } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { StyleSheet, css } from 'aphrodite';
import { FluidObject } from 'gatsby-image';
import { Text } from 'components/Text/Text';
import { BREAKPOINTS } from 'shared/constants';
import { black, white } from 'shared/colors';

interface Props {
  vidRef: React.MutableRefObject<HTMLVideoElement | null>;
  backgroundTexture: {
    fluid: FluidObject;
  };
  backgroundVideo: {
    file: {
      url: string;
    };
  };
}

const BackgroundVideo = ({
  vidRef,
  backgroundTexture,
  backgroundVideo: {
    file: { url },
  },
}: Props) => {
  const [isVideoPaused, setIsVideoPaused] = useState<boolean | undefined>(
    vidRef.current?.paused
  );

  return (
    <>
      <video
        autoPlay
        muted
        playsInline
        loop
        className={css(styles.bgVideo)}
        ref={vidRef}
      >
        <source src={url} />
        Sorry, your browser doesn't support HTML5 video :(
      </video>
      <BackgroundImage
        fluid={backgroundTexture.fluid}
        className={css(styles.texture)}
      />
      <button
        className={css(styles.pauseButton)}
        onClick={() => {
          isVideoPaused ? vidRef.current?.play() : vidRef.current?.pause();
          setIsVideoPaused((isVideoPaused) => !isVideoPaused);
        }}
      >
        <Text
          text={isVideoPaused ? 'Play' : 'Pause'}
          extraStyle={styles.pauseButtonText}
        />
      </button>
    </>
  );
};

export default BackgroundVideo;

const styles = StyleSheet.create({
  bgVideo: {
    position: 'absolute',
    objectFit: 'cover',
    zIndex: 0,
    opacity: 0.6,
    top: '120px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100vw * 9 / 16)',
    borderTop: `1px solid ${white}`,
    overflow: 'hidden',

    [BREAKPOINTS.TABLET]: {
      top: '90px',
      height: '100vw',
    },
  },
  texture: {
    width: '100%',
    position: 'absolute',
    height: '200vh',
    left: 0,
    zIndex: 1,
    opacity: 0,
    display: 'none',
  },
  pauseButton: {
    top: '140px',
    right: '20px',
    fontFamily: 'Titling Gothic FB Wide',
    position: 'absolute',
    color: white,
    textAlign: 'right',
    padding: '0px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    zIndex: 10,
    ':focus': {
      border: '1px solid white',
      backgroundColor: black,
    },
    [BREAKPOINTS.TABLET]: {
      top: '110px',
    },
  },
  pauseButtonText: {
    textTransform: 'uppercase',
    fontSize: '12px',
    [BREAKPOINTS.TABLET]: {},
  },
});
